<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <output-table
              ref="inoutTopOutputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '출금액TopN',
                titleTextClass: 'text-seizeblack',
                detailData: detailOutputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['출금'],
                itemsPerPage: 10,
              }"
            >
            </output-table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <input-table
              ref="inoutTopInputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '입금액 TopN',
                titleTextClass: 'text-seizeblack',
                detailData: detailInputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['입금'],
                itemsPerPage: 10,
              }"
            >
            </input-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable.vue";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";

Vue.use(SeizePlugin);
export default {
  name: "InoutTop",
  components: {
    KTPortlet,
    "input-table": TransactionDataTable,
    "output-table": TransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
      categoryMapData: {},
      detailInputDataArray: this.getInputData(),
      detailOutputDataArray: this.getOutputData(),
    };
  },
  mounted() {
    document.title = "입출금액TopN";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.$refs.inoutTopInputTable.setData(this.baseDate, this.getInputData());
      this.$refs.inoutTopOutputTable.setData(
        this.baseDate,
        this.getOutputData()
      );
    },
    getInputData() {
      const baseDate = Vue.dateToDateString("2019-06-30");
      const before6M = Vue.getBeforeMonthString(baseDate, 6);
      const result = this.$store.getters.requestItem.items.filter(
        (item) =>
          item.inout_code === 1 &&
          Vue.dateToDateString(item.tran_date) > before6M
      );
      result.sort(function (a, b) {
        return a.tran_amt > b.tran_amt ? -1 : a.tran_amt < b.tran_amt ? 1 : 0;
      });
      return result;
    },
    getOutputData() {
      const baseDate = Vue.dateToDateString("2019-06-30");
      const before6M = Vue.getBeforeMonthString(baseDate, 6);
      const result = this.$store.getters.requestItem.items.filter(
        (item) =>
          item.inout_code === -1 &&
          Vue.dateToDateString(item.tran_date) > before6M
      );
      result.sort(function (a, b) {
        return a.tran_amt > b.tran_amt ? -1 : a.tran_amt < b.tran_amt ? 1 : 0;
      });
      return result;
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
