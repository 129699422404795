var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('output-table',_vm._b({ref:"inoutTopOutputTable"},'output-table',{
              baseDate: _vm.baseDate,
              titleText: '출금액TopN',
              titleTextClass: 'text-seizeblack',
              detailData: _vm.detailOutputDataArray,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              inoutInitialFilter: ['출금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('input-table',_vm._b({ref:"inoutTopInputTable"},'input-table',{
              baseDate: _vm.baseDate,
              titleText: '입금액 TopN',
              titleTextClass: 'text-seizeblack',
              detailData: _vm.detailInputDataArray,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              inoutInitialFilter: ['입금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }